import $ from 'jquery';
import '@fortawesome/fontawesome-free/js/all.js';
import 'bootstrap';
import 'bootstrap/js/dist/util.js'
import 'popper.js';
import 'jquery-ui';
import { Chart } from 'chart.js';
import 'bootstrap-table'
import 'bootstrap-table/dist/bootstrap-table.min.css'
import { Calendar as FullCalendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import 'summernote/dist/summernote-bs4';
import 'summernote/dist/summernote-bs4.css';

// Adding JQuery into the window variable for global use
window.$ = $;
window.jQuery = $;
window.Chart = Chart;
window.FullCalendar = FullCalendar;
window.dayGridPlugin = dayGridPlugin;
window.bootstrapPlugin = bootstrapPlugin;

// Activate Tool Tips
$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})